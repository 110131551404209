<template>
  <div id="app">
    <snackbar v-if="isSnackbar" :message="message" :backgroundColor="backgroundColor"></snackbar>

    <Init class="body-min-width"></Init>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { store } from "@/store/store";
import { _fbId, _firebase, _providerId } from "./provider-config.js";

import FirebaseActions from "./mixins/FirebaseActions.js";

import firebase from "firebase/app";
import "firebase/auth";

import Utility from "./mixins/Utility";
import {setUserPropertiesFunc} from "@/utilities";


import { getTelcoTpaySdkUrlGeneration } from "./utilities";

console.log("@", eventBus);
export default {
  mixins: [Utility, FirebaseActions],
  name: "App",
  data() {
    return {
      isSnackbar: null,
      message: "",
      backgroundColor: "",
      mobileWeb: false,
      mobileBrowserController: true,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "newSubscription", "newPurchase", "getDisplayLanguage", "getDisplayLanguageCode", "appConfig", "getCountryListByCode","getPairingFlag"]),
  },
  watch: {
    subscriberid(val) {
      this.fetchSubscriberList();
      this.fetchPurchaseList();

      if (val) {
        this.firebaseAnonymousLogin();
        this.setUserProfileDetails();
      }
    },
    newSubscription(val) {
      if (val) {
        console.log("got the update of new subscription");
        // Fetch subscription list after each subscription.
        this.fetchSubscriberList();
      } else if (!val) {
        console.log("subscription cancelled");
      }
    },
    newPurchase(val) {
      if (val) {
        console.log("got the update of new purchase");

        // Fetch purchase list after each purchase.
        this.fetchPurchaseList();
      }
    },
    $route(to, from) {
      this.landingRoutes(to);
      this.setupLandingUrl(to);
      const subscriber = localStorage.getItem("subscriberDetails") ? localStorage.getItem("subscriberDetails") : "";
      if ((to.name === "Watchlist" || to.name === "Bold" || to.name === "Profile") && !subscriber) {
        this.$router.push({ name: "Home" });
      }
    },
  },

  created() {
    this.getCountryList().then(response => {
      console.log("The Country List --> ", response);
      this.setCountryListByCode(response);
        this.setCountryCodesList(response);
    }).catch(err => {
      console.log(err);
    })

    // Check if user is offline
    this.checkOffline();

    // Check if user is offline
    this.checkOnline();

    // Check if the User is logged into firebase
    firebase.auth().onAuthStateChanged((user) => {
      console.log("THE USER FROM APP.VUE", user);

      if (user && user.uid) {
        this.setFirebaseUserUID(user.uid);
      }

      if (this.subscriberid && !user) {
        console.log("LOGIN FOR ALREADY LOGGEDIN USER");
        this.firebaseAnonymousLogin();
      }
    });

    // Detect browser.
    this.checkBrowser();
  },

  mounted() {
    this.actGetUTMData();

    if(this.subscriberid) {
      this.setUserProfileDetails();
    }

    eventBus.$on("re-login-success", () => {
      this.fetchSubscriberList();
    });

    // Updating locale based on localstorage value.

    eventBus.$on("check-local-storage", (data) => {
      this.$i18n.locale = data;
    });

    // Event listener for facebook sdk load.
    window.addEventListener("facebook-loaded", this.loadFacebookSdk);

    eventBus.$on("snackBarMessage", (payload) => {
      this.messagesSnackbar(payload);
    });

    this.checkScreenSize();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize());
    eventBus.$off("re-login-success");
  },

  methods: {
    ...mapMutations(["setNewSubscription", "setCurrentBrowser", "setRtl", "setFirebaseUserUID", "setProfileid", "setCountryListByCode", "setCountryCodesList", "setPairingFlag"]),
    ...mapActions(["listProfiles", "getCountryList"]),
    setupLandingUrl (to) {
      if (to.params.lang) {
        let seletedLanguage = "eng";
        if (to.params.lang == "ar") {
          seletedLanguage = "ara";
        } 
        localStorage.setItem("setDisplayLanguageCode", seletedLanguage);
         this.$i18n.locale = seletedLanguage;
        // eventBus.$emit("check-local-storage", seletedLanguage);
        this.setRtl(seletedLanguage == "eng" ? false : true);
      }
    },
    setUserProfileDetails() {
      const localProfileId = localStorage.getItem("currentProfileId");
      const localSubscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (!localProfileId && !localSubscriberProfileDetails) {
        this.listProfiles().then((profileResponse) => {
          if (profileResponse.data.reason) {
            // this.formError = this.$t(profileResponse.data.reason);
          } else {
            if (profileResponse.data.totalcount === 1) {

              localStorage.setItem("currentProfileId", profileResponse.data.data[0].profileid);
              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileResponse.data.data[0]));

              this.setProfileid(profileResponse.data.data[0].profileid);
            }
          }
        });
      }
    },

    actGetUTMData() {
      let utm_details_global = {};
      let utm_source = this.getParameterByName("utm_source");
      let utm_medium = this.getParameterByName("utm_medium");
      let utm_campaign = this.getParameterByName("utm_campaign");
      if (utm_source) {
        utm_details_global.utm_source = utm_source;
      }
      if (utm_source) {
        utm_details_global.utm_medium = utm_medium;
      }
      if (utm_source) {
        utm_details_global.utm_campaign = utm_campaign;
      }

      localStorage.setItem("utm_details_global", JSON.stringify(utm_details_global));
    },
    landingRoutes(route) {
      //assigning i18n locale.
      let routename = route.name;
      if (routename === "enStcLanding" && !localStorage.getItem("setDisplayLanguageCode")) {
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", "eng");
        //Initially setting the application locale to english.
        this.$i18n.locale = "eng";
        eventBus.$emit("check-local-storage", "eng");
        this.setRtl(false);
      } else if (routename === "arStcLanding" && !localStorage.getItem("setDisplayLanguageCode")) {
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", "ara");
        //Initially setting the application locale to english.
        this.$i18n.locale = "ara";
        eventBus.$emit("check-local-storage", "ara");
        this.setRtl(true);
      } else if (routename === "enStcLanding" && localStorage.getItem("setDisplayLanguageCode")) {
        localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", "eng");
        //Initially setting the application locale to english.
        this.$i18n.locale = "eng";
        eventBus.$emit("check-local-storage", "eng");
        this.setRtl(false);
      } else if (routename === "arStcLanding" && localStorage.getItem("setDisplayLanguageCode")) {
        localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", "ara");
        //Initially setting the application locale to english.
        this.$i18n.locale = "ara";
        eventBus.$emit("check-local-storage", "ara");
        this.setRtl(true);
      } else if (!localStorage.getItem("setDisplayLanguageCode")) {
        const userLang = navigator.language || navigator.userLanguage;
        let seletedLanguage = "eng";

        if (userLang.includes("ar")) {
          seletedLanguage = "ara";
        }
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", seletedLanguage);
        //Initially setting the application locale to english.
        this.$i18n.locale = seletedLanguage;
        eventBus.$emit("check-local-storage", seletedLanguage);
        this.setRtl(seletedLanguage == "eng" ? false : true);
      } else if (localStorage.getItem("setDisplayLanguageCode")) {
        this.$i18n.locale = localStorage.getItem("setDisplayLanguageCode");
      }


      if (location.pathname == '/') {
					if (this.getPairingFlag) {
						this.$router.push({ path: "/pairing" });
					} else {
						let query = this.$route.query;
						if ( query.couponcode ) {
							localStorage.setItem("campaign-coupon", query.couponcode)
						} 
						// query.lang = ();
						this.$router.replace({ name: "Home", query:query,params: { lang: this.$i18n.locale == 'ara' ? 'ar' : 'en' }});
					}
      }
    },

    checkBrowser() {
      // Get the user-agent string
      let userAgentString = navigator.userAgent;

      //detect chrome
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

      console.log("Is chrome?", chromeAgent);
      console.log("Is firefox", firefoxAgent);

      switch (true) {
        case chromeAgent:
          this.setCurrentBrowser("Chrome");
          break;
        case firefoxAgent:
          this.setCurrentBrowser("Firefox");
          break;
      }
    },

    checkOffline() {
      window.addEventListener("offline", () => {
        this.isSnackbar = true;
        this.message = "You are offline,Please check your connectivity";
        this.backgroundColor = "red";
        setTimeout(() => {
          this.isSnackbar = false;
        }, 2000);
      });
    },

    checkOnline() {
      window.addEventListener("online", () => {
        this.isSnackbar = true;
        this.message = "Back to Online";
        this.backgroundColor = "green";
        setTimeout(() => {
          this.isSnackbar = false;
        }, 2000);
      });
    },

    messagesSnackbar(response) {
      this.isSnackbar = response.state;
      this.message = response.message;
      this.backgroundColor = response.color;
      setTimeout(() => {
        this.isSnackbar = false;
      }, 5000);
    },

    fetchSubscriberList() {
      let payload = {
        subscriptionstatus: "ALL",
      };

      let subscriberDetails = localStorage.getItem("subscriberDetails");

      if (subscriberDetails) {
        subscriberDetails = JSON.parse(subscriberDetails).data;
      } 

      store.dispatch("listSubscription", payload).then((response) => {
        console.log("response", response);
        if (!response.data.reason) {
          store.commit("setSubscriptionList", response.data.data);
          store.commit("setIsManageCards", true);
          this.setNewSubscription(false);
          this.fetchHighestDate(response.data.data);

          console.log("THE SUBSCRIPTION LIST FOR NEW SUB --> ",response.data.data);
          localStorage.setItem("subscriptionDetails", JSON.stringify(response.data.data[0]));
          setUserPropertiesFunc(subscriberDetails, response.data.data[0]);
        } else if (response.data.errorcode === 2001) {
          // Error handling
        }
      });
    },

    fetchPurchaseList() {
      store
        .dispatch("listPurchases")
        .then((response) => {
          if (!response.data.reason) {
            store.commit("setPurchasesList", response.data.data);
          }
        })
        .catch((error) => console.log(error));
    },

    fetchHighestDate(list) {
      let longExpiry = null;
      let planid = null;

      list.forEach((element) => {
        let date = new Date(element.nextbilling);
        // Initially storing first element to longExpiry.
        if (!longExpiry) {
          longExpiry = date;
          planid = element.planid;
        }
        // Checking the highest date.
        if (date > longExpiry) {
          planid = element.planid;
        }
      });
      let index = list.findIndex((el) => {
        return el.planid === planid;
      });
      if (index > -1) {
        let item = list[index];
      }
    },

    loadFacebookSdk() {
      window.fbAsyncInit = function() {
        FB.init({
          appId: _fbId,
          autoLogAppEvents: true,
          xfbml: false,
          version: "v7.0",
        });
      };
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },

    checkScreenSize() {
      window.addEventListener("resize", () => {
        eventBus.$emit("re-render-carousel", true);
      });
    },

    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
  },
  components: {
    Init: () => import(/* webpackChunkName: "header" */ "@/components/Init/Init.vue"),
    snackbar: () => import("@/components/snackbar/Snackbar.vue"),
  },
};
</script>

<style lang="scss">
@import "@/sass/_base.scss";
.body-min-width {
  min-height: 67vh;
}
@media only screen and (max-width: 700px) {
  .body-min-width {
    overflow-x: hidden !important;
  }
}
</style>
