import { _providerId } from "./../../../provider-config";

const actions = {
  fcmMessagingSubscribeToTopic: (context, topic) => {
    const fcmToken = localStorage.getItem("fcmToken");

    if (fcmToken && topic) {
      // const topicName = `${_providerId}_I_${topic}`;

      const topicName = `${_providerId}_A_${topic}`;

      const url = `https://iid.googleapis.com/iid/v1/${fcmToken}/rel/topics/${topicName}`;

      return new Promise((resolve, reject) => {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${context.getters.FIRE_FCM_SERVER_KEY}`,
          },
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              throw response;
            }
            console.log('Subscribed to "' + topicName + '"');

            return response.json();
          })
          .then((data) => {
            console.log("USER HAS BEEN SUBSCRIBED TO THE TOPIC", data);
            resolve(data);
          })
          .catch((err) => {
            console.log("An error occured while subscribing to a topic ", err);
            reject(err);
          });
      });
    } else {
      return;
    }
  },

  fcmMessagingUnsubscribeToTopic: (context, topic) => {
    const fcmToken = localStorage.getItem("fcmToken");
    if (fcmToken && topic) {
      console.log("THE PARAM -->", topic);
      const topicName = `${_providerId}_A_${topic}`;

      const url = `https://iid.googleapis.com/iid/v1/:batchRemove`;

      return new Promise((resolve, reject) => {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${context.getters.FIRE_FCM_SERVER_KEY}`,
          },
          body: JSON.stringify({
            to: `/topics/${topicName}`,
            registration_tokens: [fcmToken],
          }),
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              throw response;
            }
            console.log('Unsubscribed to "' + topicName + '"');

            return response.json();
          })
          .then((data) => {
            resolve();
            console.log("USER HAS BEEN UNSUBSCRIBED TO THE TOPIC", data);
          })
          .catch((err) => {
            console.log("An error occured while unsubscribing to a topic ", err);
            reject(err);
          });
      });
    } else {
      return;
    }
  },
};

export default { actions };
