// import Vue from "vue";
import {
  errorServiceHandler,
  makeQueryStringDync,
  toFormUrlEncoded,
  errorHandlerMiddleware,
  setHeader,
} from "../../helper";

// login API call
const actions = {
  //lookup
  lookUp: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/verifyuser${data}`, {
        method: "GET",
        headers: setHeader("json"),

        // {
        //   "Content-Type": "application/json",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.lookUp(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => reject(error));
    });
	},
	actDeckingCallService: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`https://vcms.mobiotics.com/prodv3/subscriber/v1/deckingconfig`, {
				method: "GET",
				headers: setHeader("json", "deviceToken"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.lookUp(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => reject(error));
		});
	},


  //subscriber - signup//
  signup: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.signup(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //subscriber - login//
  login: (context, payload) => {
    console.log("this========>", actions);
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      // let data = makeQueryStringDync(payload);
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/login?${data}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
        },

        // setHeader(),
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //resendlink to user
  resendLink: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/resend`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.resendLink(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => reject(error));
    });
  },

  //subscriber - logout//
  logout: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/logout`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.logout(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //get Subscriber Info API call - config endpoint
  subscriberDetails: (context) => {
    console.log("Details ====", context.getters.appConfig.apiUtilities.subscriberDetails);

    return new Promise((resolve, reject) => {
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.subscriberDetails
            ? context.getters.appConfig.apiUtilities.subscriberDetails
            : "subscriber/v1"
        }/subscriber`,
        {
          method: "GET",
          headers: {
            "X-SESSION": `${localStorage.getItem("sessionToken")}`,
          },
        }
      )
        .then((response) => errorHandlerMiddleware(response, () => actions.subscriberDetails(context)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //subscriberEmailConfirm
  subscriberEmailConfirm: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber/emailconfirm`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),
        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.subscriberEmailConfirm(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  subscriberVerify: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/verifyuser`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.subscriberVerify(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //forgot password request//
  forgotPasswordRequest: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/forgotpassword`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.forgotPasswordRequest(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //forgot password confirmation//
  forgotPasswordConfirmation: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/forgotpassword`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: setHeader("json"),

        // {
        //   "Content-Type": "application/json",
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) =>
          errorHandlerMiddleware(response, () => actions.forgotPasswordConfirmation(context, payload))
        )

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  actions,
};
