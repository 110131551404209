let _fbId = null;
let _googleId = null;
let _clientKey = null;
let _providerId = null;
let _firebase = null;
let _projectName = null;

export default (providerId) => {
	console.log("providerId", providerId);
	switch (providerId) {
		case "oiempmle":
			// _providerId = "noorplay";
			// _clientKey = "kcnRb0689wtL8RUn";

			_providerId = providerId;
			_clientKey = "fevIbvJvRA0ZAwcb";

			_fbId = "228226828540122";
			_googleId = "860691220599-k4ddv2ebv1pa2ltnqhnv0rh6r64vv389.apps.googleusercontent.com";
			_firebase = "preprod";
			_projectName = "VLIVE";

			window.document.title = "Noorplay";
			// document
			//   .getElementById("favicon")
			// .setAttribute("href", require("@/assets/favIcons/net5.png"));

			break;
		case "net54321":
			_providerId = providerId;
			_clientKey = "eQycQ9sylHkX8275";
			_fbId = "3535167906569477";
			_googleId = "860691220599-k4ddv2ebv1pa2ltnqhnv0rh6r64vv389.apps.googleusercontent.com";
			_firebase = "net5";
			_projectName = "NET5";

			window.document.title = "NET5 - Newest English Movies App | Stream Movies Online Now";
			document
				.getElementById("meta-desc")
				.setAttribute(
					"content",
					"Watch Free & Premium English & Foreign Movies Online in HD on NET5. The Newest Video Streaming App in India for Exclusive Entertainment Anytime & Anywhere."
				);
			document
				.getElementById("meta-key")
				.setAttribute(
					"content",
					"Net5, hollywood movies online, best hollywood movies, english movies online, ott platform, english movies app, hollywood movie app, ott platform app, ott app, english movies online, english movie app"
				);
			document.getElementById("favicon").setAttribute("href", require("@/assets/favIcons/net5.png"));

			break;
		case "z01x1wPo":
			_providerId = providerId;
			_clientKey = "YTncjcVWu8HSQZBg";
			_fbId = "1160173767700678";
			_googleId = "404583237788-rr2841mkabc9n1kihqr7g3lqgd68brc0.apps.googleusercontent.com";
			_firebase = "nammaflix";
			_projectName = "NAMMAFLIX";

			window.document.title = "Nammaflix";
			document.getElementById("favicon").setAttribute("href", require("@/assets/favIcons/nammaflix.png"));

			break;
		case "noorplay":
			// ****************** betav1 **********

			// ----- Noorplay provider ----

			// _providerId = providerId;
			// _clientKey = "kcnRb0689wtL8RUn";

			// ----- Noorplay provider - end ----

			// ----- Manishkr provider ----

			// _providerId = "manishkr";
			// _clientKey = "M1jV9g8g8SuLmoKp";

			// ----- Noorplay provider - end ----

			// PROD - Google and Facebook creds for login - TEST
			// _fbId = "147575555971331";
			// _googleId = "745323876347-so4f0uqbvnosbvfifpjrt0o91gtbvedp.apps.googleusercontent.com";
			// _firebase = "preprod";

			// ***************** betav1 ***********

			// ***************** prod *************
			_providerId = providerId;
			_clientKey = "btJ85rtEsEhyrE0t";

			_fbId = "147575555971331";
			_googleId = "745323876347-so4f0uqbvnosbvfifpjrt0o91gtbvedp.apps.googleusercontent.com";
			_firebase = "noorplay";

			// _firebase = "preprod";
			// _firebase = "preprodPOC";
			// *************** prod ****************

			_projectName = "Noor Play";
			// window.document.title = "Noor Play - unlimited TV shows and more";
			// document
			//   .getElementById("favicon")
			//    .setAttribute("href", require("@/assets/favIcons/Noorplayfav.svg"));
			break;
	}
};

export { _providerId, _googleId, _fbId, _clientKey, _firebase, _projectName };