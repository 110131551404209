import { makeQueryStringDync, toFormUrlEncoded, errorHandlerMiddleware, setHeader } from "../../helper.js";

const actions = {
	featureContentList: (context, payload) => {
		//alert("contentlist called")
		//debugger;
		return new Promise((resolve, reject) => {
			// let cat = payload.params.category;
			// if (payload.params.category) {
			// 	payload.params.category = '["' + JSON.parse(cat) + '"]';
			// }
			// if (payload.params.notingenre) {
			// 	let notkids = payload.params.notingenre;
			// 	payload.params.notingenre = '["' + JSON.parse(notkids) + '"]';
			// }
			// let categoryPayload = JSON.parse(JSON.stringify(payload));
			// if (categoryPayload.params.category) {
			// 	categoryPayload.params.category = encodeURI(JSON.stringify([categoryPayload.params.category]));
			// }
			// if (categoryPayload.params.orderby) {
			// 	categoryPayload.params.orderby = encodeURI(JSON.stringify(categoryPayload.params.orderby));
			// }
			// if (payload.params.orderby) {
			// 	payload.params.orderby = encodeURI(JSON.stringify(payload.orderby));
			// } 
			// if (payload.orderby) {
			// 	payload.orderby = encodeURI(JSON.stringify(payload.orderby));
			// } 
			// if (payload.params.orderby) {
			// 	payload.params.orderby = encodeURI(JSON.stringify(payload.params.orderby));
			// } 
			let data = makeQueryStringDync(payload);
			//fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content${data}`, {
			fetch(`${context.getters.vCmsBaseUrl}${payload.endpoint}${data}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.contentList(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	contentList: (context, payload) => {
		//alert("contentlist called")
		//debugger;
		return new Promise((resolve, reject) => {
			// let cat = payload.params.category;
			// if (payload.params.category) {
			// 	payload.params.category = '["' + JSON.parse(cat) + '"]';
			// }
			// if (payload.params.notingenre) {
			// 	let notkids = payload.params.notingenre;
			// 	payload.params.notingenre = '["' + JSON.parse(notkids) + '"]';
			// }
			let categoryPayload = JSON.parse(JSON.stringify(payload));
			if (categoryPayload.params.category) {
				categoryPayload.params.category = encodeURI(JSON.stringify([categoryPayload.params.category]));
			}
			if (categoryPayload.params.orderby) {
				categoryPayload.params.orderby = encodeURI(JSON.stringify(categoryPayload.params.orderby));
			}
			let data = makeQueryStringDync(categoryPayload.params);
			fetch(`${context.getters.vCmsBaseUrl}${categoryPayload.endpoint}${data}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.contentList(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
  contentListMetaCall: (context, payload) => {
    return new Promise((resolve, reject) => {
 
      let data = makeQueryStringDync(payload.params);
      fetch(`${context.getters.vCmsBaseUrl}${payload.endpoint}${data}`, {
        method: "GET",
        headers: setHeader(),
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.contentList(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listContent: (context, payload) => {
    return new Promise((resolve, reject) => {
      let data = makeQueryStringDync(payload);
			fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content${data}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.listContent(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	listTrailers: (context, payload) => {
		//alert("trailers called")
		return new Promise((resolve, reject) => {
			let data = makeQueryStringDync(payload);
			fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/metadata/trailer${data}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.listContent(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
  listBundleItem: (context, payload) => {
    return new Promise((resolve, reject) => {
      let data = JSON.stringify(payload);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/?contentlist=${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.listBundleItem(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getContent: (context, payload) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/${payload}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.getContent(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  contentDetail: (context, payload) => {
    console.log("inside store content.js for contentDetail");
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let objectId;

      if (typeof payload == "object") {
        objectId = payload.contentid;
      } else {
        objectId = payload;
      }
      let queryUrl = `${context.getters.vCmsBaseUrl}subscriber/v1/content/${objectId}`;

      if (payload.params) {
        let dataParams = makeQueryStringDync(payload.params);
        queryUrl += dataParams;
      }

      let isAnonymousUser = localStorage.getItem("loginType") ? false : true;
      let headerObj;

      if (isAnonymousUser == true) {
        headerObj = {
          Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        };
      } else {
        headerObj = {
          "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
        };
      }
      fetch(queryUrl, {
        method: "GET",
        headers: setHeader(),
      })
        // .then(response => {
        //     if(!response.ok) {
        //         return response.json().catch(() => {
        //             throw new Error(response.status);
        //         }).then((message) => {
        //             console.log('Error message : ', message);
        //             throw message;
        //         });
        //     }
        //     return response.json();
        // })
        .then((response) => errorHandlerMiddleware(response, () => actions.contentDetail(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => reject(error));
    });
  },
  listSuggestions: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/suggestions${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.listSuggestions(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  contentSearch: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/search${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.contentSearch(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  genreList: (context) => {
    return new Promise((resolve, reject) => {
      // if (!payload) {
      //   reject({ error: "payload not found" });
      // }
      // let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/genre`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.genreList(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  genreContentList: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      // let data = makeQueryStringDync(payload);
      fetch(
        `${context.getters.vCmsBaseUrl}subscriber/v1/content?category=["${payload.category}"]&objecttype=${payload.objecttype}&genre=${payload.genre}&objectlanguage=["${payload.objectlanguage}"]&productionyear=${payload.productionyear}&pagesize=30`,
        {
          method: "GET",
          headers: setHeader(),

          // {
          //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
          // },
        }
      )
        .then((response) => errorHandlerMiddleware(response, () => actions.genreContentList(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //get content stream url//
  contentStreamUrl: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload.params);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/package/${payload.contentid}`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
        // },
      })
        // .then(response => {
        //   if(!response.ok) {
        //       return response.json().catch(() => {
        //           throw new Error(response.status);
        //       }).then((message) => {
        //           console.log('Error message : ', message);
        //           throw message;
        //       });
        //   }
        //   return response.json();
        // })
        .then((response) => errorHandlerMiddleware(response, () => actions.contentStreamUrl(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //list related contents//
  relatedContents: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload.params);
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/content/related/${payload.contentid}${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.relatedContents(context, payload)))

        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  listi18nLanguage: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/i18n/language`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
        // },
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.listi18nLanguage(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  authorizeCurrentStreamApi: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/stream/content/authorize`, {
        method: "GET",
        headers: setHeader(),
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.authorizeCurrentStreamApi(context)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setCurrentStreamApi: (context, payload) => {
    let data = toFormUrlEncoded(payload.params);

    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/stream/content/${payload.contentId}`, {
        method: "POST",
        body: data,
        headers: setHeader(),
      })
				.then((response) => errorHandlerMiddleware(response, () => actions.setCurrentStreamApi(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unsetCurrentStreamApi: (context, payload) => {

    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/stream/content/${payload.contentId}`, {
        method: "DELETE",
        headers: setHeader(),
      })
				.then((response) => errorHandlerMiddleware(response, () => actions.unsetCurrentStreamApi(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  actions,
};
