export { userClass };

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import SimpleCrypto from "simple-crypto-js";

import util from "@/mixins/Utility";

import {setUserPropertiesFunc} from "@/utilities";

console.log("util", util);

class userClass {
  constructor() {
    import(/* webpackChunkName: "profileClass" */ /* webpackMode: "lazy" */ "./profileClass").then((profile) => {
      this.profile = new profile.profileClass();
    });

    eventBus.$on("lookUp", (data) => {
      this.lookUp(data);
    });

    eventBus.$on("subscriberSignup", (data) => {
      this.subscriberSignup(data);
    });

    eventBus.$on("subscriberLogin", (data) => {
      this.subscriberLogin(data);
    });

    eventBus.$on("resend-link", (data) => {
      this.resendLink(data);
    });

    eventBus.$on("mobileVerify", (data) => {
      this.mobileVerify(data);
    });

    eventBus.$on("subscriberLogout", () => {
      this.subscriberLogout();
    });

    eventBus.$on("subscriberDetails", (data) => {
      this.subscriberDetails(data);
    });

    eventBus.$on("forgotPasswordRequest", (data) => {
      this.forgotPasswordRequest(data);
    });
    eventBus.$on("forgotPasswordConfirmation", (data) => {
      this.forgotPasswordConfirmation(data);
    });
  }

  lookUp(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("lookUp", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          eventBus.$emit("lookup-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  subscriberSignup(payload) {
    let params = payload.type ? payload.params : payload;
    eventBus.$emit("loader", true);
    store
      .dispatch("signup", params)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          let data = {
            response: response.data,
            data: payload,
          };

          if (response.data.reason && payload.type) {
            eventBus.$emit("social-login-response-captcha-error", response);
          } else if (!response.data.reason && payload.type) {
            let newPayload = { ...payload };

            delete newPayload.params.captcha;

            //social signup//
            this.subscriberLogin(newPayload);

            //logging social signup google analytics event.
            eventBus.$emit("social-signup-response", data);
          } else {
            //normal signup//
            if (response.data.statusCode != 401) {
              eventBus.$emit("signup-response", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  subscriberLogin(payload, cb) {
    console.log("login payload", payload);

    // payload.autologin = true;

    localStorage.setItem("login-payload", JSON.stringify(payload));

    let loginType = {};

    let GA = {};

    let simpleCrypto = new SimpleCrypto(store.getters.secret_key);
    switch (payload.app) {
      case "SELF":
        loginType = {
          type: "Email",
          email: payload.email,
          password: simpleCrypto.encrypt(payload.password),
        };
        localStorage.setItem("loginType", JSON.stringify(loginType));
        break;
      case "FB":
        loginType = {
          type: "FB",
          fbToken: payload.params.fbtoken,
        };
        localStorage.setItem("loginType", JSON.stringify(loginType));
        break;
      case "Google":
        loginType = {
          type: "Google",
          gtoken: payload.params.gtoken,
        };
        localStorage.setItem("loginType", JSON.stringify(loginType));
        break;
      case "Apple":
        loginType = {
          type: "Apple",
          appletoken: payload.params.appletoken,
        };
        localStorage.setItem("loginType", JSON.stringify(loginType));
        break;
    }
    eventBus.$emit("loader", true);
    store
      .dispatch("login", payload.params)
      .then((response) => {
        if(!response.data.errorcode && response.data.successtype !== "CAPTCHA") {
          localStorage.setItem("sessionToken", response.data.success);
  
          this.subscriberDetails(payload);
        }


        if (payload.app == "FB" || payload.app == "Google") {
          if (response.data && response.data.errorcode) {
            eventBus.$emit("social-login-failed", response.data)
            GA.status = "Failed";
            GA.error_reason = response.data.reason;
          } else {
            GA.status = "Success";
          }

          const browser = util.methods.getPlatformType();

          GA.platform = util.methods.getPlatform();

          GA.platform_type = browser.browser;

          console.log("THE LOGIN GA SOCIAL", GA);
        }

        eventBus.$emit("loader", false);
        if (payload.autologin && response.data.errorcode) {
          util.methods.logoutCB();
          return;
        }
        if (response.data && response.data.errorcode != 6066 && response.data.errorcode != 6055) {
          console.log("THIS IS CHECK LOG --- ", payload, payload.type !== "login");

          if (response.successtype === "CAPTCHA") {
            console.log("THIS IS THE CAPTCHA PAYLOAD AND RESPONSE", payload, response);
          }

          //after login if user doesn't exist//
          window.reLoginRequired = false;
          // if (response.data.errorcode === 6001) {
          //   console.log("THE USER REACHED HERE -- ", response);
          //   //response.data.reason

          //   // Check if Social Captcha is enabled fron the config
          //   // if (store.getters.appConfig.featureEnabled.isCaptchaEnabled) {
          //   //   eventBus.$emit("social-captch-event", { payload, response, isSocialSignup: true });
          //   // } else {
          //    //this.subscriberSignup(payload);
          //   //}
          // }
          //after facebook login if email is required//
           if (payload.app === "FB" && response.data.errorcode === 6003) {
            eventBus.$emit("auth-close");
            eventBus.$emit("facebookemail", payload);
          }

          //after login if popup type is not login//
          else if (payload && (payload.app === "Google" || payload.app === "FB")) {
            if (payload.emailRequired) {
              eventBus.$emit("facebookemail-response", response.data);
            }

            // if (store.getters.appConfig.featureEnabled.isCaptchaEnabled) {
            if (response.data.successtype === "CAPTCHA") {
              console.log("THIS IS THE CAPTCHA PAYLOAD AND RESPONSE --- NEW ---", payload, response);

              eventBus.$emit("social-captch-event", { payload, response });

              return;
            }
            // }

            console.log("THIS BLOCK FOR ERROR HANDLING -- ", response);

            if (response.data.errorcode) {
              eventBus.$emit("social-login-response-captcha-error", response);
              return;
            }

            eventBus.$emit("close-social-captcha", true);
            localStorage.setItem("sessionToken", response.data.success);

            // this.subscriberDetails(payload);

            //emitting this event for social login google analytics data.
            eventBus.$emit("social-login-event", payload);
            eventBus.$emit("login-response", response.data);
            eventBus.$emit("login-response-social-profile", response.data);
          }
          //after login if popup type is login//
          else {
            eventBus.$emit("login-response", response.data);
          }

          if (typeof cb == "function") {
            cb();
          }
        } else {
          if (payload.autologin) {
            util.methods.logoutCB();
          } else {
            if (response.data.statusCode != 401) {
              eventBus.$emit("login-response", response.data);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        util.methods.logoutCB();

        eventBus.$emit("loginError", error);
      });
  }

  subscriberDetails(payload) {
    console.log("subscriber details payload", payload);

    const subPayload = payload;

    eventBus.$emit("loader", true);
    store
      .dispatch("subscriberDetails")
      .then((response) => {
        eventBus.$emit("loader", false);

        localStorage.setItem("subscriberDetails", JSON.stringify(response));
        store.commit("commitsubscriberdetails");

        console.log("THE SUBS DETAILS PAYLOAD --> SET ", subPayload)


        eventBus.$emit("subscriberDetails-set-success", subPayload);

        //invoking this event for clever tap login event.
        eventBus.$emit("invoke-subscriber-details", response.data);

        eventBus.$emit("enableOverlayLoader", false);
        eventBus.$emit("auth-close");

        // Subscribe to the topic ("Subscriber ID")
        store
          .dispatch("fcmMessagingSubscribeToTopic", response.data.subscriberid)
          .then((data) => {
            console.log("user is subscibed to topic", "SUBSCRIBER");
          })
          .catch((err) => {
            console.log("Not subscribed to ", "SUBSCRIBER");
          });

        console.log("response.data ------>>", response.data);

        // Subscribe to the topic ("Country")
        store
          .dispatch("fcmMessagingSubscribeToTopic", response.data.country)
          .then((data) => {
            console.log("user is subscibed to topic", "SUBSCRIBER");
          })
          .catch((err) => {
            console.log("Not subscribed to ", "SUBSCRIBER");
          });

        //window reload when profile is switched.
        if (payload && payload.app !== undefined && payload.app === "pinswitch") {
          eventBus.$emit("profile-switched", response.data);
          //window.location.reload();
        }

        //if login type is facebook//
        if (payload && payload.app !== undefined && payload.app === "FB") {
          eventBus.$emit("closeFacebookEmail", false);
        }

        // Set user properties for Firebase Event
        setUserPropertiesFunc(response.data);
      })
      .catch((error) => console.log(error));
  }

  resendLink(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("resendLink", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        console.log("response", response);
        if (response.data) {
          eventBus.$emit("resend-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  mobileVerify(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("subscriberVerify", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          console.log("mobile verified", response.data);
          eventBus.$emit("mobileverify-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  subscriberLogout() {
    store
      .dispatch("logout")
      .then(() => {
        eventBus.$emit("logout-success");
      })
      .catch((error) => console.log(error));
  }

  forgotPasswordRequest(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("forgotPasswordRequest", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        console.log("forgot-password", response);
        if (response.data) {
          eventBus.$emit("forgotpassword-response", response.data);
        } 
      })
      .catch((error) => console.log(error));
  }

  forgotPasswordConfirmation(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("forgotPasswordConfirmation", payload)
      .then((response) => {
        console.log("confirmation response", response);
        eventBus.$emit("loader", false);
        if (response.data) {
          eventBus.$emit("forgotPasswordConfirmation-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }
}
